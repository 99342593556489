@import url('https://fonts.googleapis.com/css2?family=Moul&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');

@font-face {
  font-family: 'KhmerOS Siemreap';
  src: local("'KhmerOS Siemreap"), url('../assets/fonts/KhmerOS_siemreap.ttf') format('truetype');
}

:root {
  --primary:#0B4668;
  --secondary: #f50057;
}

#root {
  background-color: #ececec;
}

/* change style scroll bar - Dont work on firefox */
/* width */
*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

* {
  scrollbar-width: thin;
}

/* Track */
* ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

* ::-webkit-scrollbar-thumb {
  background: var(--primary);
}

img {
  pointer-events: none;
}

.container {
  padding: 30px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  }

.button {

  padding: 10.5px 10px !important;
  min-width: 135px !important;
  text-transform: capitalize !important;
}

@media only screen and (max-width: 600px) {
  .button {
    padding: 8.5px 5px !important;
    min-width: 110px !important;
    text-transform: capitalize !important;
  }
}

.tableX-container {
  padding: 50px 30px;
}

.tableX-container-sm {
  padding: 30px 30px 50px 30px;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

th.MuiTableCell-root.MuiTableCell-head {
  font-size: 14px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin-left: 10px;
}

.required::after {
  content: ' *';
  color: var(--secondary);
}

.no-border td,
.no-border {
  border: 0 !important;
}

/* disable number increase arrow */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* product carousel */
.carousel__slide-focus-ring {
  outline: 0 !important;
}

.dragging-helper-class div {
  transition: 0.2s linear;
  height: 120px;
  width: 120px;
  cursor: pointer;
}