@import url(https://fonts.googleapis.com/css2?family=Moul&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap);
@font-face {
  font-family: 'KhmerOS Siemreap';
  src: local("'KhmerOS Siemreap"), url(/static/media/KhmerOS_siemreap.c7829629.ttf) format('truetype');
}

:root {
  --primary:#0B4668;
  --secondary: #f50057;
}

#root {
  background-color: #ececec;
}

/* change style scroll bar - Dont work on firefox */
/* width */
*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

* {
  scrollbar-width: thin;
}

/* Track */
* ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

* ::-webkit-scrollbar-thumb {
  background: #0B4668;
  background: var(--primary);
}

img {
  pointer-events: none;
}

.container {
  padding: 30px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  }

.button {

  padding: 10.5px 10px !important;
  min-width: 135px !important;
  text-transform: capitalize !important;
}

@media only screen and (max-width: 600px) {
  .button {
    padding: 8.5px 5px !important;
    min-width: 110px !important;
    text-transform: capitalize !important;
  }
}

.tableX-container {
  padding: 50px 30px;
}

.tableX-container-sm {
  padding: 30px 30px 50px 30px;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

th.MuiTableCell-root.MuiTableCell-head {
  font-size: 14px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin-left: 10px;
}

.required::after {
  content: ' *';
  color: #f50057;
  color: var(--secondary);
}

.no-border td,
.no-border {
  border: 0 !important;
}

/* disable number increase arrow */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* product carousel */
.carousel__slide-focus-ring {
  outline: 0 !important;
}

.dragging-helper-class div {
  transition: 0.2s linear;
  height: 120px;
  width: 120px;
  cursor: pointer;
}
:root {
  --primary: #098092;
  --primary-light: #3a99a7;
  --secondary: #f50057;
}

/* NOTE: the order of these styles DO matter */
.DateInput_input {
  font-size: inherit;
  padding: 4px 10px;
}

.DateRangePickerInput_clearDates:hover {
  background-color: transparent;
}
.DateRangePickerInput_clearDates:hover .DateRangePickerInput_clearDates_svg {
  fill: #f50057;
  fill: var(--secondary);
}

.CalendarDay__today {
  /*Your styles here*/
  background-color: bisque;
}

.CalendarDay__selected_span {
  background-color: #3a99a7;
  background-color: var(--primary-light);
  border-color: #098092;
  border-color: var(--primary);
}
.CalendarDay__hovered_span {
  background-color: #3a99a7;
  background-color: var(--primary-light);
  color: white;
  border-color: #098092;
  border-color: var(--primary);
}
.CalendarDay__selected_start,
.CalendarDay__selected_end {
  background-color: #098092;
  background-color: var(--primary);
  border-color: #098092;
  border-color: var(--primary);
}
/* Will edit everything selected including everything between a range of dates */
/* .CalendarDay__selected_span { */
/* //background */
/* background: #82e0aa; */
/* //text */
/* color: white; */
/* //default styles include a border */
/* border: 1px solid var(--secondary); */
/* } */

/* // Will edit selected date or the endpoints of a range of dates */
/* .CalendarDay__selected {
  background: var(--primary-light);
  color: white;
} */

/* // Will edit when hovered over. _span style also has this property */
/* .CalendarDay__selected:hover {
  background: var(--primary-light);
  color: white;
} */

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
/* .CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
} */

